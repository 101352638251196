/* General styles */
body {
    margin: 0;
    font-family: "Google Sans", Roboto, "Noto Sans Myanmar UI", Arial, sans-serif;
    background-color: #202124;
    color: #e8eaed;
}

/* Container for the sign-in form */
.signin-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

/* Sign-in box with left and right sections */
.signin-box {
    background-color: #050505;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 40px;
    padding-right: 40px;
    width: 750px;
    height: 100%;
    max-width: 750px;
    max-height: 300px;
    border-radius: 25px;
    display: flex;
    justify-content: space-between;
}

.signin-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
}

.signin-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
    padding-top: 70px;
}

/* Style for the Google logo */
.google-logo img {
    width: 40px;       
    height: auto;         
    display: block;
}

/* Title and subtitle styling */
.signin-title {
    margin: 10px 0 15px;
    font-size: 30px;
    color: #e8eaed;
    font-family: Arial;
    font-weight: lighter;
}

.signin-subtitle {
    margin: 0 0 20px;
    font-size: 14px;
    color: #e8eaed;
    font-family: Arial;
    font-weight: lighter;
}

/* Input field styling
.signin-input {
    width: 90%;
    padding: 12px;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid #bcb8b8;
    border-radius: 4px;
    background-color: #080808;
    color: #ffffff;
    font-size: 16px;
} */
 

/* Container for input and label */
.input-container {
    position: relative;
    width: 90%;
    margin-bottom: 15px;
}

.email-container {
   padding-top: 100px;
}

/* Input styles */
.signin-input {
    width: 100%;
    padding: 15px 10px 10px 15px;
    border: 1px solid #555; /* Default border */
    border-radius: 4px;
    background-color: #1a1a1a;
    color: #fff;
    font-size: 16px;
    outline: none;
    /* transition: border-color 0.3s; */
}

.signin-input:focus {
    border-color: #8eabd8; /* Highlighted border color */
}

/* Error state for input */
.signin-input.error {
    border-color: #8eabd8;
}

/* Floating label styles */
.floating-label {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    font-size: 14px;
    color: #aaa;
    pointer-events: none;
    transition: all 0.3s ease;
}

/* Move label to the top when active */
.floating-label.active {
    top: -1px;
    left: 10px;
    font-size: 12px;
    background-color: #050505;
    padding: 3px;
    color: #8eabd8; /* Color when focused */
}

/* Error state for label */
.floating-label.active.error {
    color: #e3afaf;
}

/* Error message container */
.error-message-container {
    display: flex;
    align-items: center;
    margin-top: 5px;
    color: #e3afaf;
    font-size: 12px;
}

/* Error icon */
.error-icon {
    margin-right: 5px;
    font-size: 12px;
    color: #e3afaf;
}

/* Error text */
.error-text {
    margin: 0;
    font-size: 12px;
    color: #e3afaf;
}

/* Link styles */
.signin-link {
    color: #8ab4f8;
    text-decoration: none;
    font-size: 12px;
}

.signin-link:hover {
    text-decoration: underline;
}

/* Guest mode text styling */
.guest-mode-text {
    font-size: 12px;
    color: #ffffff;
    font-family: "Google Sans",roboto,"Noto Sans Myanmar UI",arial,sans-serif;
    font-weight: lighter;
    margin-top: auto;
}

/* Sign-in button styling */
.signin-button {
    width: 25%;
    padding: 10px;
    background-color: #a9c8f0;
    color: #090542;
    font-size: 12px;
    font-weight: 500;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    margin-left: auto;
    margin-top: auto;
}

.signin-button:hover {
    background-color: #3367d6;
}

/* Create account link */
.create-account-link {
    color: #8ab4f8;
    margin-top: 15px;
    font-size: 14px;
    text-decoration: none;
}

.create-account-link:hover {
    text-decoration: underline;
}

.error-message {
    height: 20px; /* Reserve space for the error message */
    color: rgb(146, 173, 231);
    font-size: 14px;
    margin: 5px 0; 
    visibility: hidden; /* Hide the text but keep the space */
}

.error-message.visible {
    visibility: visible; /* Show the error message */
}


/* Footer styling */
.signin-footer {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 850px;
}

.footer-left{
    display: flex;
    align-items: left;
}

.footer-right {
    display: flex;
    align-items: right;
}

.footer-link {
    color: #ffffff;
    font-size: 10px;
    padding-left: 25px;
    text-decoration: none;
}

.footer-link:hover {
    text-decoration: underline;
}

.language-select {
    background-color: #202124; 
    color: #e8eaed;           
    border: none;              
    border-radius: 4px;
    font-size: 10px;
    outline: none;            
    cursor: pointer;
}

.language-select::after {
    content: '▼';
    position: absolute;
    right: 10px;
    pointer-events: none;
    color: #8ab4f8;
}

.language-select:hover {
    background-color: #35363a;
}

/* Progress bar at the top of the container */
.progress-bar {
    position: absolute;  /* Position the bar relative to the container */
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: linear-gradient(90deg, #4285f4, #34a853, #fbbc05, #ea4335);
    background-size: 400% 100%;
    animation: glowingBar 2s infinite linear;
    border-radius: 4px;
    z-index: 1000; /* Ensure it appears above other elements */
}

/* Animation for glowing effect */
@keyframes glowingBar {
    0% {
        background-position: 0% 50%;
    }
    100% {
        background-position: 100% 50%;
    }
}

/* Show Password Container */
.show-password-container {
    display: flex;
    align-items: center;
    margin-top: 10px; /* Adjust spacing from the input */
    font-size: 14px;
    color: #aaa;
    cursor: pointer;
}

/* Show Password Checkbox */
.show-password-checkbox {
    appearance: none; /* Remove default browser styles */
    width: 18px;
    height: 18px;
    border: 1px solid #555; /* Match input border */
    border-radius: 3px;
    background-color: #1a1a1a;
    cursor: pointer;
    margin-right: 10px; /* Space between checkbox and label */
    transition: all 0.2s ease;
}

.show-password-checkbox:checked {
    background-color: #8eabd8; /* Highlighted color when checked */
    border-color: #8eabd8;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.show-password-checkbox:checked::after {
    content: '✔'; /* Checkmark symbol */
    color: #fff;
    font-size: 12px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Label for Show Password */
.show-password-label {
    color: #aaa; /* Match label color with form theme */
    font-size: 14px;
    cursor: pointer;
}

.show-password-label:hover {
    color: #8eabd8; /* Highlight color on hover */
}


/* General styles remain the same */

/* Mobile responsive styles */
@media only screen and (max-width: 768px) {
    .signin-box {
        flex-direction: column;
        max-width: 85%;
        max-height: 90%;
        width: 100%;
        margin-right: 10px;
        border-radius: 0%;
    }

    .signin-left {
        flex: none; /* Reset flex for stacked layout */
        align-items: start;
        text-align: left; /* Center align for smaller screens */
        padding: 0;
        margin-bottom: 10%;
    }

    .signin-right {
        align-items: end;
        text-align: right; /* Center align for smaller screens */
        padding: 0;
        margin-bottom: 20px;
    }

    .signin-right {
        padding-top: 0; /* Remove extra padding for smaller screens */
    }

    .google-logo img {
        width: 50px; /* Slightly larger logo for smaller screens */
    }

    .signin-title {
        font-size: 24px;
    }

    .signin-subtitle {
        font-size: 14px; /* Slight adjustment for subtitle */
    }

    .input-container {
        width: 100%; /* Full width input for smaller screens */
    }

    .signin-input {
        font-size: 14px;
        width: 95%;
        padding: 15px 10px 10px 15px;
        border: 1px solid #555;
        border-radius: 4px;
        background-color: #1a1a1a;
        color: #fff;
        outline: none;
    }

    .guest-mode-text {
        font-size: 12px; /* Adjust text size */
        margin-top: 30px;
        text-align: left;
    }

    .signin-button {
        width: 15%;
        font-size: 14px;
        margin: 20px 0;
        margin-top: 10%;
    }

    .signin-footer {
        margin-top: 20px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        width: 90%;
    }
    
    .footer-left {
        align-items: start;
        justify-content:left;
        margin-bottom: 10px;
    }

    .footer-right {
        align-items:end;
        justify-content: right;
        margin-bottom: 10px;
    }

    .footer-link {
        font-size: 12px;
        margin-bottom: 5px; /* Add space between links */
    }

    .language-select {
        font-size: 12px; /* Adjust dropdown size */
    }
}

/* For very small screens */
@media only screen and (max-width: 480px) {
    .signin-title {
        font-size: 25px; /* Smaller title for very small screens */
    }

    .signin-subtitle {
        font-size: 12px; /* Further adjustment for subtitle */
    }

    .signin-button {
        font-size: 12px;
        padding: 10px; /* Adjust padding */
        width: 20%;
    }
}
