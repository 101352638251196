/* General Container */
.success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #202124;
    font-family: "Google Sans", Roboto, Arial, sans-serif;
}

/* Success Box */
.success-box {
    width: 90%;
    max-width: 600px;
    background: #202124;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
}

/* Phishing Icon */
.phishing-icon {
    width: 300px;
    height: 300px;
}

/* Title */
.success-title {
    font-size: 24px;
    font-weight: bold;
    color: #d93025;
    margin-bottom: 16px;
}

/* Messages */
.success-message {
    font-size: 16px;
    color: #ebebec;
    margin-bottom: 12px;
    line-height: 1.5;
}

.success-reminder {
    font-size: 14px;
    color: #5f6368;
    margin-top: 20px;
}

/* Button */
.back-to-safety-button {
    padding: 12px 20px;
    font-size: 14px;
    font-weight: bold;
    background-color: #4285f4;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
}

.back-to-safety-button:hover {
    background-color: #357ae8;
}

/* Summary container */
.summary-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #1a1a1a;
    color: #fff;
    font-family: "Google Sans", Roboto, "Noto Sans Myanmar UI", Arial, sans-serif;
    padding: 20px;
    text-align: center;
}

/* Summary box styling */
.summary-box {
    background-color: #242424;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    max-width: 600px;
    width: 100%;
}

/* Title styling */
.summary-title {
    font-size: 40px;
    font-weight: bold;
    color: #ff6b6b; /* Red for emphasis */
    margin-bottom: 20px;
}

/* Main message */
.summary-message {
    font-size: 20px;
    margin-bottom: 10px;
}

.important-text {
    color: #f4c542; /* Highlighted color */
    font-weight: bold;
}

/* Submessage */
.summary-submessage {
    font-size: 16px;
    color: #ddd;
    margin-bottom: 15px;
    line-height: 1.5;
}

/* Note */
.summary-note {
    font-size: 14px;
    color: #bbb;
    margin-bottom: 25px;
}

/* Button styling */
.summary-button {
    background-color: #4285f4;
    color: #fff;
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.summary-button:hover {
    background-color: #357ae8;
}

